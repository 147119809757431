import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminPanel from './components/AdminPanel/Main';
import AffiliatePanel from './components/AffiliatePanel/Affiliate';
import LoginPage from './components/Login/page';
import { AuthProvider } from './AuthContext';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/affiliate-panel" element={<AffiliatePanel />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;