import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ROOT_URL } from '../../../utils/variable';
import { decryptToken } from '../../../utils/tokenValidation';
import './AffiliatePanel.css';

const StarrySky = () => {
  const generateStars = () => {
    const stars = [];
    for (let i = 0; i < 100; i++) {
      const top = Math.random() * 100 + '%';
      const left = Math.random() * 100 + '%';
      const size = Math.random() * 3 + 1;
      stars.push(
        <div
          key={`star-${i}`}
          className="star"
          style={{
            top,
            left,
            width: `${size}px`,
            height: `${size}px`,
            animationDuration: `${Math.random() * 2 + 2}s`,
          }}
        />
      );
    }
    return stars;
  };

  const generateShootingStars = () => {
    const shootingStars = [];
    for (let i = 0; i < 3; i++) {
      const delay = Math.random() * 5 + 's';
      shootingStars.push(
        <div
          key={`shooting-star-${i}`}
          className="shooting-star"
          style={{
            top: `${Math.random() * 50}%`,
            left: `${Math.random() * 50}%`,
            animationDelay: delay,
          }}
        />
      );
    }
    return shootingStars;
  };

  return (
    <div className="starry-sky">
      {generateStars()}
      {generateShootingStars()}
    </div>
  );
};

const AffiliatePanel = () => {
  const [affiliate, setAffiliate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [generatedLink, setGeneratedLink] = useState('');
  const [linkLoading, setLinkLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchAffiliateData();
  }, []);

  const fetchAffiliateData = async () => {
    setError(''); // Reset error state
    try {
      const sessionToken = decryptToken();
      const affiliateID = localStorage.getItem('affiliate_id');
      const headers = {
        Authorization: `Bearer ${sessionToken}`,
      };
      const response = await axios.get(
        `${ROOT_URL}api/affiliate/stats?affiliate_id=${affiliateID}`,
        { headers }
      );
      setAffiliate(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching affiliate data:', error);
      setError('Failed to fetch affiliate data.');
      setLoading(false);
    }
  };

  const generateAffiliateLink = async () => {
    setError(''); // Reset error state
    try {
      setLinkLoading(true);
      const sessionToken = decryptToken();
      const headers = {
        Authorization: `Bearer ${sessionToken}`,
      };
      const response = await axios.get(`${ROOT_URL}api/affiliate/generate-link`, { headers });
      setGeneratedLink(response.data.affiliate_link);
    } catch (error) {
      console.error('Error generating affiliate link:', error);
      setError('Failed to generate affiliate link.');
    } finally {
      setLinkLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedLink);
    alert('Link copied to clipboard!');
  };

  if (loading) {
    return (
      <div className="affiliate-panel-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="affiliate-panel-container">
      <StarrySky />
      <img src="/assets/images/admin/ultraspin.png" alt="Ultraspin Logo" className="logo" />
      <div className="affiliate-greeting">Hello, {affiliate?.name || 'Affiliate'}!</div>

      <div className="stats-container">
        <h3>Your Statistics</h3>
        {affiliate ? (
          <ul className="stats-list">
            <li className="stat-item">
              <span>Player Count:</span>
              <span>{affiliate.player_count || 0}</span>
            </li>
            <li className="stat-item">
              <span>Commission %:</span>
              <span>{affiliate.commission_percentage || 0}%</span>
            </li>
            <li className="stat-item">
              <span>Estimated Commission (MTD):</span>
              <span>${affiliate.estimated_commission_mtd || 0}</span>
            </li>
          </ul>
        ) : (
          <p>No affiliate data available.</p>
        )}
      </div>

      <div className="generate-link-container">
        <button
          onClick={generateAffiliateLink}
          className="generate-link-button"
          disabled={linkLoading}
        >
          {linkLoading ? 'Generating...' : 'Generate Affiliate Link'}
        </button>
        {generatedLink && (
          <div className="generated-link-wrapper">
            <input
              type="text"
              value={generatedLink}
              readOnly
              className="generated-link-input"
              onClick={(e) => e.target.select()}
            />
            <button className="copy-link-button" onClick={copyToClipboard}>
              Copy Link
            </button>
          </div>
        )}
      </div>

      {error && <p className="error-message">{error}</p>}

      <div className="waves">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none">
          <defs>
            <path
              id="wave"
              d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="wave">
            <use href="#wave" x="50" y="3" />
          </g>
          <g className="wave">
            <use href="#wave" x="50" y="0" />
          </g>
          <g className="wave">
            <use href="#wave" x="50" y="6" />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default AffiliatePanel;
