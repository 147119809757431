import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import './LoginPage.css';

const StarrySky = () => {
  const generateStars = () => {
    const stars = [];
    for (let i = 0; i < 100; i++) {
      const top = Math.random() * 100 + '%';
      const left = Math.random() * 100 + '%';
      const size = Math.random() * 3 + 1; // Random sizes for stars
      stars.push(
        <div
          key={`star-${i}`}
          className="star"
          style={{
            top,
            left,
            width: `${size}px`,
            height: `${size}px`,
            animationDuration: `${Math.random() * 2 + 2}s`,
          }}
        />
      );
    }
    return stars;
  };

  const generateShootingStars = () => {
    const shootingStars = [];
    for (let i = 0; i < 3; i++) {
      const delay = Math.random() * 5 + 's';
      shootingStars.push(
        <div
          key={`shooting-star-${i}`}
          className="shooting-star"
          style={{
            top: `${Math.random() * 50}%`,
            left: `${Math.random() * 50}%`,
            animationDelay: delay,
          }}
        />
      );
    }
    return shootingStars;
  };

  return (
    <div className="starry-sky">
      {generateStars()}
      {generateShootingStars()}
    </div>
  );
};

const LoginPage = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch('https://api.ultraspin.vip/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username_or_email: usernameOrEmail, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.detail || 'Login failed');
      }

      login(data.authToken);

      if (data.is_admin) {
        navigate('/admin');
      } else if (data.is_affiliate) {
        navigate('/affiliate-panel');
      } else {
        throw new Error('Unauthorized access');
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <StarrySky />
      <img src="/assets/images/admin/ultraspin.png" alt="Ultraspin Logo" className="logo" />
      <p className="welcome">PARTNERS</p>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit} className="login-form">
        <div className="form-group">
          <label htmlFor="usernameOrEmail">Username</label>
          <input
            id="usernameOrEmail"
            type="text"
            value={usernameOrEmail}
            onChange={(e) => setUsernameOrEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="login-button" disabled={isLoading}>
          {isLoading ? <div className="spinner" /> : 'Login'}
        </button>
      </form>

      {/* Waves at the bottom */}
      <div className="waves">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none">
          <defs>
            <path
              id="wave"
              d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="wave">
            <use href="#wave" x="50" y="3" />
          </g>
          <g className="wave">
            <use href="#wave" x="50" y="0" />
          </g>
          <g className="wave">
            <use href="#wave" x="50" y="6" />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default LoginPage;
